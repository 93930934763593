<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Tools</h3>
                    <h1>Trading Calculator</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
                    <!-- <div class="cta">
                        <a href="" class="blue-btn">Login to MagnoFX Terminal</a>
                    </div> -->
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/calculator/calculator-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->

    <!--- Calculator Start --->
    <section class="calculator">
        <div class="container">
            <div class="title">
                <h2>Calculator</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
            </div>
        </div>
    </section>
    <!--- Calculator End --->
</template>