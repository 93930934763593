<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content pb-0">
                <div class="banner-home__content__text overlapped-2">
                    <h3 class="gradient-title">Platform</h3>
                    <h1>MagnoFX Terminal</h1>
                    <p>Unleash your trading potential with the web trader app with top-tier features.</p>
                    <div class="cta">
                        <a href="" class="blue-btn">Login to MagnoFX Terminal</a>
                    </div>
                </div>
                <div class="banner-home__content__img overlapped-2">
                    <img src="assets/images/platform-magnoterminal/platform-magnoterminal-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->

    <!-- Two Columns Start -->
    <section class="two-columns">
        <div class="container">
            <h2>Trade up with our high-speed, customized web app</h2>
            <p>Enjoy seamless and flexible trading on the Exness Terminal, a secure and streamlined web trader platform for trading over a hundred popular CFDs.</p>
            <div class="row flex-md-row-reverse">
                <div class="col-md-6">
                    <div class="image">
                        <img src="assets/images/platform-magnoterminal/img1.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text">
                        <h4>Watchlist</h4>
                        <p>Plan strategies with signals that incorporate various analytical approaches.</p>
                        <h4>One-click trading mode</h4>
                        <p>Track your orders in the portfolio area with details like type, volume, prices, take profit, stop loss, open time, swap, and profit and loss.</p>
                        <h4>Instruments tabs </h4>
                        <p>Choose from multiple available chart types and customize them with indicators, drawing tools and more. Charting is provided by TradingView.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Two Columns End -->  

    <!-- Two Columns Start -->
    <section class="two-columns pt-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="image">
                        <img src="assets/images/platform-magnoterminal/img2.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text">
                        <h4>Trading account management</h4>
                        <p>View your account details such as trading history, balance, equity, margin, free margin, margin level, and leverage settings in real-time.</p>
                        <h4>Account types</h4>
                        <p>Enjoy flexibility on a web trader platform that is compatible with all MagnoFX trading account types that are supported by MetaTrader 5.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Two Columns End -->  
    
    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3>  -->
                <h2>Why MagnoFX</h2>
                <p>Our leading market conditions, trading features and proprietary protections give your strategy the advantage it deserves.</p> 
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-umbrella.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Stop Out Protection</h3>
                            <p>Trade FX with an innovative market protection feature designed to safeguard your positions from short-term market fluctuations, preventing or minimizing stop outs and delays.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-candles.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Low and stable spreads</h3>
                            <p>Engage in FX trading with consistently low and and transparent fee. Benefit from narrow spreads that remain steady, even amidst economic news releases and market events.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Leverage the frequent price movements of popular currency pairs with lightning-fast execution. Have your FX trading orders executed within milliseconds.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-zero.png" alt="">
                        </div>
                        <div class="text">
                            <h3>0% stop out level</h3>
                            <p>Trade Forex online with a unique market protection feature that shields your positions against temporary market volatility and delays or avoids stop outs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-scale.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Customizable leverage</h3>
                            <p>Choose the one that will maximize your returns while minimizing your costs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-dollar.png" alt="">
                        </div>
                        <div class="text">
                            <h3>No overnight fees</h3>
                            <p>We've eliminated swap fees for most of our instruments, including majors, crypto, and gold.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Three Columns End -->  
</template>