<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content align-items-start pb-0">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Platform</h3>
                    <h1>MagnoFx phone app</h1>
                    <p>Trade the markets at any time from anywhere.</p>
                    <div class="cta">
                        <a href=""><img src="assets/images/google-play.png" alt=""></a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/platform-app/platform-app-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->

    <!-- Trading Slider Start -->
    <section class="trading-slider">
        <div class="container">
            <h2>Engage in trading without compromising.</h2>
            <div class="row">
                <div class="trading-slider__details col-md-4 order-2 order-md-1">
                    <div>
                        <h3 class="gradient-title">Withdrawals</h3>
                        <h2>Fast withdrawals</h2>
                        <p>Deposit instantly and get your withdrawal approved in under 60 minutes.</p>
                    </div>
                    
                    <div>
                        <h3 class="gradient-title">Stop outs</h3>
                        <h2>0% stop out level</h2>
                        <p>Trade without limits with our exclusive stop-out feature</p>
                    </div>
                </div>
                <div class="col-md-4 order-1 order-md-2">
                    <div class="trading-slider__content">
                        <carousel>
                            <slide v-for="slide in slidesToShow" :key="slide">
                                <div>
                                    <img :src="'/assets/images/home/slide'+slide+'.jpg'" alt="MagnoFx" title="MagnoFx">
                                </div>
                            </slide>

                            <template #addons>
                                <pagination />
                            </template>
                        </carousel>
                    </div>
                </div>
                <div class="trading-slider__details col-md-4 order-3 order-md-3">
                    <div>
                        <h3 class="gradient-title">Execution speed</h3>
                        <h2>Ultra-fast execution</h2>
                        <p>Lightening fast execution, regardless of the trade size.</p>
                    </div>
                    
                    <div>
                        <h3 class="gradient-title">Leverage</h3>
                        <h2>Leverage up to 500:1</h2>
                        <p>Control a larger position size with a relatively smaller amount of capital.</p>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <!-- Trading Slider End -->
   
    <!-- Three Columns Start -->
    <section class="three-columns pt-0">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3>  -->
                <h2>Why MagnoFX</h2>
                <p>Our leading market conditions, trading features and proprietary protections give your strategy the advantage it deserves.</p> 
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-umbrella.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Stop Out Protection</h3>
                            <p>Trade FX with an innovative market protection feature designed to safeguard your positions from short-term market fluctuations, preventing or minimizing stop outs and delays.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-candles.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Low and stable spreads</h3>
                            <p>Engage in FX trading with consistently low and and transparent fee. Benefit from narrow spreads that remain steady, even amidst economic news releases and market events.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Leverage the frequent price movements of popular currency pairs with lightning-fast execution. Have your FX trading orders executed within milliseconds.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-zero.png" alt="">
                        </div>
                        <div class="text">
                            <h3>0% stop out level</h3>
                            <p>Trade Forex online with a unique market protection feature that shields your positions against temporary market volatility and delays or avoids stop outs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-scale.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Customizable leverage</h3>
                            <p>Choose the one that will maximize your returns while minimizing your costs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-dollar.png" alt="">
                        </div>
                        <div class="text">
                            <h3>No overnight fees</h3>
                            <p>We've eliminated swap fees for most of our instruments, including majors, crypto, and gold.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Three Columns End -->  

    <!-- Two Columns Start -->
    <section class="two-columns pt-0">
        <div class="container">
            <h2>Analytical resources</h2>
            <p>Take advantage of our unparalleled crypto payments ecosystem: effortlessly deposit funds through cryptocurrencies, enjoy round-the-clock access, and experience hassle-free fund withdrawals.</p>
            <div class="row flex-md-row-reverse pt-5">
                <div class="col-md-6">
                    <div class="image">
                        <img src="assets/images/platform-app/img1.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text pt-0">
                        <h4>Responsive, interactive, and optimised for mobile </h4>
                        <p>Our charts have been meticulously designed to ensure optimal interactivity on both powerful desktop computers and touchscreen tablets or phones.</p>
                        <h4>Supported in multiple languages </h4>
                        <p>The trading platform in localised and supports a number of languages. </p>
                        <h4>Adaptable styling </h4>
                        <p>Modify the default appearance and feel to precisely reflect your personal style. You can also copy and paste a tonne of pre-made examples.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Two Columns End -->  
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
    data(){
        return{
            slidesToShow:3
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    methods: {}
}
</script>