<template>
    <!--- Banner Start --->
    <section class="banner">
        <div class="container">
            <div class="banner__content">
                <img src="assets/images/partnership/partnership-banner.jpg" alt="">
                <div class="text">
                    <h1>MagnoFX Partnership programs</h1>
                    <p>Generate a passive income by referring clients to a global broker.</p>
                    <a href="" class="blue-btn">Start Now!</a>
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->
    
    <!--- Two Columns Box Start --->
    <section class="two-columns-box">
        <div class="container">
            <div class="title">
                <h2>What we offer</h2>
                <p>Our leading market conditions, trading features and proprietary protections give your strategy the advantage it deserves.</p>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="two-columns-box__content">
                        <h4>Revenue Share Program</h4>
                        <h3>Earn up to 60% of our revenue</h3>
                        <p>Get up to 60% of the commission paid by each active trader.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="two-columns-box__content">
                        <h4>Fixed Commission Program</h4>
                        <h3>Earn up to $12 <br>per Lot</h3>
                        <p>Get a fixed commission per lot and make the most from high volume referrals.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Two Columns Box End --->

    <!-- two-columns-overlapped Start -->
    <section class="two-columns-overlapped no-bg-image">
        <div class="container">
            <div class="row flex-md-row-reverse">
                <div class="col-md-6 d-flex">
                    <div class="image">
                        <img src="assets/images/home/devices.png" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text">
                        <h2>Tools and services you can offer clients</h2>
                        <p>Engage in online trading at any time and from any place. Available on web, mobile, and desktop platforms.</p>
                        <div>
                            <a href="" class="arrow-right">Web Trading Platform</a>
                        </div>
                        <div>
                            <a href="" class="arrow-right">Phone App</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- two-columns-overlapped End -->  
    
    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <h2>Why become a MagnoFX partner?</h2>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content list">
                        <div class="text">
                            <h3>Partners Program</h3>
                            <p>Become a part of our Partners Program and team up with a reliable global broker. </p>
                            <p>Benefit from access to multiple commission-paying trading accounts.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content list">
                        <div class="text">
                            <h3>Rewards & Withdrawals</h3>
                            <p>Gain access to highly competitive, volume-based commission structure.</p>
                            <p>Withdraw profits quickly with our fast withdrawal process.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content list">
                        <div class="text">
                            <h3>Extensive Partner Dashboard</h3>
                            <p>Monitor your referral commission in real-time.</p>
                            <p>Advanced tracking system to track leads</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content list">
                        <div class="text">
                            <h3>Best Trading Conditions</h3>
                            <p>Spreads from 0.0 pips</p>
                            <p>Fast execution</p>
                            <p>No re-quotes</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content list">
                        <div class="text">
                            <h3>Sign-up Effortlessly:</h3>
                            <p>Simply follow our straightforward setup process</p>
                            <p>Access marketing tools and receive your unique referral link</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content list">
                        <div class="text">
                            <h3>Unlimited Earning Potential:</h3>
                            <p>Earn up to $12 per  standard lot</p>
                            <p>Daily commission payouts Additional rebates</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Three Columns End -->  
</template>