<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content pb-0 align-items-start">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Bonus</h3>
                    <h1>100% Bonus</h1>
                    <p>Enhance Your Trading Potential with Our Exclusive Deposit Bonus!</p>
                    <div class="cta">
                        <a href="" class="blue-btn mr-3">Register</a>
                        <a href="" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/bonus/bonus-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->

    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3> -->
                <!-- <h2>Why trade indices with MagnoFX</h2>
                <p>Access extensively traded global indices, including the S&P and the US Tech 100, through a broker who understands your requirements.</p> -->
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-umbrella.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Stop Out Protection</h3>
                            <p>Trade FX with an innovative market protection feature designed to safeguard your positions from short-term market fluctuations, preventing or minimizing stop outs and delays.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-candles.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Low and stable spreads</h3>
                            <p>Engage in FX trading with consistently low and and transparent fee. Benefit from narrow spreads that remain steady, even amidst economic news releases and market events.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Leverage the frequent price movements of popular currency pairs with lightning-fast execution. Have your FX trading orders executed within milliseconds.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-zero.png" alt="">
                        </div>
                        <div class="text">
                            <h3>0% stop out level</h3>
                            <p>Trade Forex online with a unique market protection feature that shields your positions against temporary market volatility and delays or avoids stop outs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-scale.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Customizable leverage</h3>
                            <p>Choose the one that will maximize your returns while minimizing your costs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-dollar.png" alt="">
                        </div>
                        <div class="text">
                            <h3>No overnight fees</h3>
                            <p>We've eliminated swap fees for most of our instruments, including majors, crypto, and gold.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Three Columns End -->  

    <!-- Two Columns Start -->
    <section class="two-columns dark">
        <div class="container">
            <div class="row flex-md-row-reverse">
                <div class="col-md-6">
                    <div class="image">
                        <img src="assets/images/bonus/bonus-guy.png" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text">
                        <h2>Receive a bonus on each Deposit with MagnoFX</h2>
                        <p>We're excited to announce a generous 100% Deposit Bonus available to all traders. Whether you're new to trading or an experienced investor, this presents an excellent chance to amplify your trading capital and excel in the Forex market.</p>
                        <p>This amazing opportunity lets you double your initial deposit, providing you with added leverage to explore the intricacies of Forex trading further.</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Two Columns End -->
    
    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Bonus Terms and Conditions:</h2>
            <div class="list">
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 1" :class="activeFaqs==1?'active':''">
                        What is a deposit bonus? 
                    </div>
                    <div v-show="activeFaqs == 1">
                        <div class="answer">
                            <p>A deposit bonus is a promotional offer provided by Magno Fx to incentivize traders to deposit funds into their trading accounts. This bonus typically adds a certain percentage of the deposited amount as a bonus, effectively increasing the trader's trading capital.</p>

                            <p>For example, if a broker offers a 100% deposit bonus and a trader deposits $500 into their account, they will receive an additional $500 as a bonus, making their total trading capital $1000.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 2" :class="activeFaqs==2?'active':''">
                        What are the advantages of a forex deposit bonus?
                    </div>
                    <div v-show="activeFaqs == 2">
                        <div class="answer">
                            <p>Magno Fx's deposit bonus can offer traders several advantages, including increased trading capital, potential for higher returns, and opportunity to explore strategies for new traders.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 3" :class="activeFaqs==3?'active':''">
                        What is the available leverage with a matching bonus? 
                    </div>
                    <div v-show="activeFaqs == 3">
                        <div class="answer">
                            <p>The maximum available leverage with a bonus is 1:200</p> 
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 4" :class="activeFaqs==4?'active':''">
                        Can the bonus be withdrawn? 
                    </div>
                    <div v-show="activeFaqs == 4">
                        <div class="answer">
                            <p>The bonus is available for withdrawal if once the bonus user trades a specified volume in standard lots. </p>
                            <p>Bonus withdrawal formula:  <br>
                                = Bonus SUM / 2</p>
                            <p>*CFD lots are equivalent to 1/10th of a standard lot.</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <!--- Full Accordion Accordion End --->
</template>
<script>
    // import { useMainStore } from "@/store/index.ts";
    export default {
        // setup(){
        //     return { store: useMainStore() };
        // },
        data(){
            return {
                activeFaqs:'1',
            }
        }
    }
</script>