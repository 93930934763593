<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Commodities</h3>
                    <h1>Trade commodities with confidence</h1>
                    <p>Diversify your portfolio while gaining access to the ever-changing global commodity market with exclusive market protections.</p>
                    <div class="cta">
                        <a href="" class="blue-btn mr-3">Register</a>
                        <a href="" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/commodities/commodities-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->
    
    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3> -->
                <h2>Why trade Commodities with MagnoFX</h2>
                <p>Trade precious metals and energies with trading conditions designed to provide your strategy with a competitive edge.</p>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-shield.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Security of funds</h3>
                            <p>Engage in commodity market trading with the assurance of Negative Balance Protection. Rest assured, your funds are securely held in segregated accounts across a selection of major crypto exchanges, providing our clients with confidence in the safety of their capital.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-candles.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Low and stable spreads</h3>
                            <p>Maintain low trading costs, even amidst price fluctuations. Benefit from consistently narrow spreads, unaffected by high-impact market news or economic events.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-cycle.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Never miss a pip. Execute your orders within milliseconds across all of our web and mobile trading platforms.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Three Columns End -->  
    
    <!-- Trading Instruments Start -->
    <section class="trading-instrument">
        <div class="container">
            <div class="text-center">
                <h2>Commodities trading conditions</h2>
                <h3 class="gradient-title">Market Execution</h3>
            </div>
            <div>
                <div class="trading-options">
                    <button onclick="dropdownMethod()" class="dropbtn">Select Mehod</button>
                    <div class="trading-options-list" id="trading-options-list">
                        <a href="" class="active">ECN</a>
                        <a href="">Zero</a>
                    </div>
                </div>
                <div class="trading-instrument__content" >
                    <div class="trading-instruments-table">
                        <table class="inner">
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th class="text-end">Avg. spread <br>pips</th>
                                    <th class="text-end">Commission <br>per lot/side</th>
                                    <th class="text-end">Margin <br>1:500 </th>
                                    <th class="text-end">Long swap <br>pips</th>
                                    <th class="text-end">Short swap <br>pips</th>
                                    <th class="text-end">Stop level* <br>pips</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        AUDUSDm
                                        <span>Australian Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            • Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                                <tr>
                                    <td>
                                        DXYm
                                        <span>US Dollar Index</span>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        EURUSDm
                                        <span>Euro vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        GBPUSDm
                                        <span>Great Britain Pound vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        NZDUSDm
                                        <span>New Zealand Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCADm
                                        <span>US Dollar vs Canadian Dollar </span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCHFm
                                        <span>US Dollar vs Swiss Franc</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDJPYm
                                        <span>US Dollar vs Japanese Yen</span>
                                        <div class="green-text">
                                            Extended Swap-free available 
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <!-- Trading Instruments End -->

    <!--- Two Columns Accordion Start --->
    <section class="two-columns-accordion">
        <div class="container">
            <!-- <h2>There are many advantages in trading Forex. </h2>
            <p>Here are a few reason for why people trade Forex:</p> -->
            <div class="row">
                <div class="col-md-6">
                    <div class="text">
                        <h2>Commodity market conditions</h2>
                        <p>The commodity market serves as a worldwide platform for trading diverse commodities, including precious metals and energies. By trading these instruments, you can speculate on the price movements of highly volatile assets such as gold and oil, without the need to purchase the underlying asset. This flexibility allows traders to profit from both rising and falling commodity prices.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list">
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 1" :class="marketConditionTab==1?'active':''">
                                Forex trading hours
                            </div>
                            <div v-show="marketConditionTab == 1">
                                <div class="answer">
                                    <p>The commodities market operates from Monday 11:05:00 GMT+0 - Friday 22:00:00 GMT+0 (daily break 22:05:00 - 23:00:00 GMT+0)</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 2" :class="marketConditionTab==2?'active':''">
                                Spreads
                            </div>
                            <div v-show="marketConditionTab == 2">
                                <div class="answer">
                                    <p>Spreads are subject to fluctuation. The spreads listed in the table above represent averages calculated. For real-time spreads, kindly consult the trading platform.</p>

                                    <p>It's important to note that spreads may widen during periods of reduced market liquidity, such as rollover time. This widening may persist until liquidity levels return to normal.</p>

                                    <p>Our narrowest spreads are offered on the ECN account, min. at 0.0 pips.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 3" :class="marketConditionTab==3?'active':''">
                                Swaps
                            </div>
                            <div v-show="marketConditionTab == 3">
                                <div class="answer">
                                    <p>Swap refers to the interest applied to all forex trading positions held overnight. Swaps are calculated at 22:00 GMT+0 daily, excluding weekends, until the position is closed. </p>

                                    <p>To assist you in estimating your swap costs, utilize our convenient MagnoFx calculator or the trading platform. It's important to note that when trading forex pairs, triple swaps are applied on Wednesdays to account for financing costs accrued over the weekend.</p>

                                    <p>For instruments indicated in the table above, swap charges are waived if you have Extended swap-free status.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 4" :class="marketConditionTab==4?'active':''">
                                Fixed margin requirements
                            </div>
                            <div v-show="marketConditionTab == 4">
                                <div class="answer">
                                    <p>Maximum available leverage for selection remains at 1:500 on commodities.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 5" :class="marketConditionTab==5?'active':''">
                                Stop level
                            </div>
                            <div v-show="marketConditionTab == 5">
                                <div class="answer">
                                    <p>Stop level values listed in the table above are subject to change and may not be accessible for traders employing specific high-frequency trading strategies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Two Columns Accordion Accordion End --->

    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Frequently asked questions </h2>
            <div class="list">
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 1" :class="activeFaqs==1?'active':''">
                        What are CFD Commodities?
                    </div>
                    <div v-show="activeFaqs == 1">
                        <div class="answer">
                            <p>Commodities are traded on the international market and consist of raw materials produced in vast quantities. Energy resources such as natural gas and crude oil, as well as precious metals including gold and silver, are examples of commodities. Typical determinants of commodity prices include economic performance, political stability, currency value, and supply and demand.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 2" :class="activeFaqs==2?'active':''">
                        What instruments can be traded on the commodities market? 
                    </div>
                    <div v-show="activeFaqs == 2">
                        <div class="answer">
                            <p>On the commodity market, a vast array of financial instruments can be traded, including energies and precious metals.
                            </p>
                            <p>Others will trade gold as a safe-haven asset to hedge their portfolios, whereas many traders will seize the opportunity presented by the frequent price fluctuations of energy commodities.</p>

                            <p>Magno Fx provides commodity derivatives trading on the most actively traded commodities in the world, such as USOIL, XNGUSD, UKOIL, XAUUSD, and XAGUSD.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 3" :class="activeFaqs==3?'active':''">
                        How does the equity of my account impact the maximum leverage I may employ? 
                    </div>
                    <div v-show="activeFaqs == 3">
                        <div class="answer">
                            <p>The maximum leverage that can be applied to an account is contingent upon the equity of that account.</p>

                            <p>10 to 49,999 USD: 1:500 maximum leverage</p>

                            <p>50,000 to 149,999 USD: 1:200 maximum leverage</p>

                            <p>&lt; $150,000 USD: 1:100 maximum leverage</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 4" :class="activeFaqs==4?'active':''">
                        Commodities can be volatile, how do you deal with price gaps? 
                    </div>
                    <div v-show="activeFaqs == 4">
                        <div class="answer">
                            <p>We understand how frustrating it is when a pending order falls victim to a price gap; If your order meets any of the following criteria, it will be executed at the first market quote that follows the gap:</p>

                            <p>In the event that your pending order is executed amidst atypical market conditions, such as periods of high volatility or low liquidity.</p>

                            <p>In the event that a gap occurs and the difference in pips between the requested price of the order and the initial market quote (post-gap) for a specific instrument is equal to or greater than a specified number of pips (gap level value).</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Full Accordion Accordion End --->
</template>
<script>
    import { useMainStore } from "@/store/index.ts";
    export default {
        setup(){
            return { store: useMainStore() };
        },
        data(){
            return {
                activeFaqs:'1',
                marketConditionTab:1
            }
        }
    }
</script>