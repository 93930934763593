<template>
    <header>
        <div class="header-nav">
            <div class="header-content">
                <div class="nav-icon" id="nav-icon2">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                <div class="logo">
                    <router-link :to="{name:'home'}" class="logo-text">
                        <img src="/assets/images/logo.png" alt="MagnoFx" title="MagnoFx">
                    </router-link>
                </div>
                <div class="menu main-nav">
                    <ul>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Trading</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'ecn'}">Raw</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Account Zero'}">Standard</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Deposit Withdrawal'}">Deposit & Withdrawals</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Fees'}">Fees</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Client Protection'}">Client & Security</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Markets</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Forex'}">Forex</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Indices'}">Indices</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Commodities'}">Commodities</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Crypto'}">Cryptocurrencies</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Platforms</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Magno Terminal'}">Magno Terminal</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Magno App'}">App</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Tools</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Calculator'}">Calculator</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Company</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Why Us'}">Why</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Contact Us'}">Contact Us</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Partners</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Partners'}">Partnership</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Bonus'}">Bonus</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <!-- <div class="cta">
                        <a href="" class="transparent">Register</a>
                        <a href="">Login</a>
                    </div> -->
                </div>
                <div class="cta">
                    <a href="" class="transparent">Register</a>
                    <a href="">Login</a>
                </div>
            </div>
           
        </div>
        <div class="overlay"></div>
    </header>
</template>
