<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Forex  CFD</h3>
                    <h1>Trade Forex with RAW spread from 0.0 pips</h1>
                    <p>Utilise better-than-market conditions to trade the world's most popular currency pairs on the global forex market.</p>
                    <div class="cta">
                        <a href="" class="blue-btn mr-3">Register</a>
                        <a href="" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img overlapped">
                    <img src="assets/images/forex/forex-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->

    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3> -->
                <h2>Why trade forex market with MagnoFX</h2>
                <p>Dominate the FX market and engage in currency trading on acclaimed forex platforms with a broker that provides an efficient and economical trading environment.</p>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-umbrella.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Stop Out Protection</h3>
                            <p>Trade FX with an innovative market protection feature designed to safeguard your positions from short-term market fluctuations, preventing or minimizing stop outs and delays.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-candles.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Low and stable spreads</h3>
                            <p>Engage in FX trading with consistently low and and transparent fee. Benefit from narrow spreads that remain steady, even amidst economic news releases and market events.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Leverage the frequent price movements of popular currency pairs with lightning-fast execution. Have your FX trading orders executed within milliseconds.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Three Columns End -->  
    
    <!-- Trading Instruments Start -->
    <section class="trading-instrument">
        <div class="container">
            <div class="text-center">
                <h2>FX trading conditions</h2>
                <h3 class="gradient-title">Market Execution</h3>
            </div>
            <div>
                <div class="trading-options">
                    <button onclick="dropdownMethod()" class="dropbtn">Select Mehod</button>
                    <div class="trading-options-list" id="trading-options-list">
                        <a href="" class="active">ECN</a>
                        <a href="">Zero</a>
                    </div>
                </div>
                <div class="trading-instrument__content" >
                    <div class="trading-instruments-table">
                        <table class="inner">
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th class="text-end">Avg. spread <br>pips</th>
                                    <th class="text-end">Commission <br>per lot/side</th>
                                    <th class="text-end">Margin <br>1:500 </th>
                                    <th class="text-end">Long swap <br>pips</th>
                                    <th class="text-end">Short swap <br>pips</th>
                                    <th class="text-end">Stop level* <br>pips</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        AUDUSDm
                                        <span>Australian Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            • Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                                <tr>
                                    <td>
                                        DXYm
                                        <span>US Dollar Index</span>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        EURUSDm
                                        <span>Euro vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        GBPUSDm
                                        <span>Great Britain Pound vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        NZDUSDm
                                        <span>New Zealand Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCADm
                                        <span>US Dollar vs Canadian Dollar </span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCHFm
                                        <span>US Dollar vs Swiss Franc</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDJPYm
                                        <span>US Dollar vs Japanese Yen</span>
                                        <div class="green-text">
                                            Extended Swap-free available 
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <!-- Trading Instruments End -->

    <!--- Two Columns Accordion Start --->
    <section class="two-columns-accordion">
        <div class="container">
            <!-- <h2>There are many advantages in trading Forex. </h2>
            <p>Here are a few reason for why people trade Forex:</p> -->
            <div class="row">
                <div class="col-md-6">
                    <div class="text">
                        <h2>FX market <br>conditions</h2>
                        <p>The forex market stands as the world's largest financial market, boasting a daily trading volume surpassing $5.5 trillion. With currency pair trading available 24 hours a day, 5 days a week, it offers boundless opportunities for traders.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list">
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 1" :class="marketConditionTab==1?'active':''">
                                Forex trading hours
                            </div>
                            <div v-show="marketConditionTab == 1">
                                <div class="answer">
                                    <p>The Forex market operates from Sunday 22:05:00 to Friday 21:59:00 (server time GMT+0).</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 2" :class="marketConditionTab==2?'active':''">
                                Spreads
                            </div>
                            <div v-show="marketConditionTab == 2">
                                <div class="answer">
                                    <p>Spreads are subject to fluctuation. The spreads listed in the table above represent averages calculated. For real-time spreads, kindly consult the trading platform.</p>

                                    <p>It's important to note that spreads may widen during periods of reduced market liquidity, such as rollover time. This widening may persist until liquidity levels return to normal.</p>

                                    <p>Our narrowest spreads are offered on the ECN account, min. at 0.0 pips.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 3" :class="marketConditionTab==3?'active':''">
                                Swaps
                            </div>
                            <div v-show="marketConditionTab == 3">
                                <div class="answer">
                                    <p>Swap refers to the interest applied to all forex trading positions held overnight. Swaps are calculated at 22:00 GMT+0 daily, excluding weekends, until the position is closed. </p>

                                    <p>To assist you in estimating your swap costs, utilize our convenient MagnoFx calculator or the trading platform. It's important to note that when trading forex pairs, triple swaps are applied on Wednesdays to account for financing costs accrued over the weekend.</p>

                                    <p>For instruments indicated in the table above, swap charges are waived if you have Extended swap-free status.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 4" :class="marketConditionTab==4?'active':''">
                                Fixed margin requirements
                            </div>
                            <div v-show="marketConditionTab == 4">
                                <div class="answer">
                                    <p>Maximum available leverage for selection remains at 1:500 on major FX instruments. </p>

                                    <p>For exotic pairs, the margin is held in accordance with the instruments’ margin requirements and is not affected by the leverage on your account.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 5" :class="marketConditionTab==5?'active':''">
                                Stop level
                            </div>
                            <div v-show="marketConditionTab == 5">
                                <div class="answer">
                                    <p>Stop level values listed in the table above are subject to change and may not be accessible for traders employing specific high-frequency trading strategies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Two Columns Accordion Accordion End --->

    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Frequently asked questions </h2>
            <div class="list">
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 1" :class="activeFaqs==1?'active':''">
                        What are the most important FX pairs to trade? 
                    </div>
                    <div v-show="activeFaqs == 1">
                        <div class="answer">
                            <p>The most actively traded currency pairs are those that provide the greatest degree of liquidity, or are the most frequently traded.</p>
                            <p>FX majors such as AUDUSD, EURUSD, GBPUSD, NZDUSD, USDCAD, USDCHF, and USDJPY are included. All of these currency trading pairs are offered for swap-free trading at Magno Fx, allowing you to hold your positions for an extended period of time without incurring any additional fees.</p>
                            <p>FX minors are additional well-liked currency pairs that traders include in their portfolios. Such pairs consist of AUDCAD, CADCHF, EURAUD, GBPCHF, and others. The majority of FX minors are also offered at Magno Fx without any overnight fees.</p>
                            <p>The precise minors that are encompassed within the swap-free programme are detailed in the table of instruments located on this page.</p>

                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 2" :class="activeFaqs==2?'active':''">
                        What is leverage in forex trading? 
                    </div>
                    <div v-show="activeFaqs == 2">
                        <div class="answer">
                            <p>In essence, leverage is the capacity to execute transactions using borrowed capital. Akin to a loan, your broker provides you with additional funds to supplement your holdings, allowing you to access larger trading positions with less of your own capital.</p>

                            <p>Leverage in foreign exchange, when coupled with a robust risk management strategy, can increase trading returns by facilitating the exploitation of smaller price fluctuations. However, failure to couple it with a meticulously planned risk management strategy may result in more substantial losses.</p>

                            <p>Prior to selecting your preferred leverage option, ensure that you have a well-thought-out risk strategy and maintain a prudent level of exposure in order to prevent excessive losses and increase the likelihood of greater returns.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 3" :class="activeFaqs==3?'active':''">
                        What is margin in online forex trading? 
                    </div>
                    <div v-show="activeFaqs == 3">
                        <div class="answer">
                            <p>In online foreign exchange trading, margin is essentially the required amount of capital to open a position. It serves as collateral in the event of price fluctuations. This is typically calculated by forex brokers as a proportion of the total position size, which is determined by the leverage you select.</p>

                            <p>In order to initiate an online forex trade, your account must contain sufficient funds to satisfy the margin requirement of the trade. One can enhance their oversight of trades by establishing a suitable margin level that is consistent with their overarching risk management strategy.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 4" :class="activeFaqs==4?'active':''">
                        How does the equity of my account impact the maximum leverage I may employ? 
                    </div>
                    <div v-show="activeFaqs == 4">
                        <div class="answer">
                            <p>The maximum leverage that can be applied to an account is contingent upon the equity of that account.</p>

                            <p>10 to 49,999 USD: 1:500 maximum leverage</p>

                            <p>50,000 to 149,999 USD: 1:200 maximum leverage</p>

                            <p>&lt; $150,000 USD: 1:100 maximum leverage</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Full Accordion Accordion End --->
</template>
<script>
    import { useMainStore } from "@/store/index.ts";
    export default {
        setup(){
            return { store: useMainStore() };
        },
        data(){
            return {
                activeFaqs:'1',
                marketConditionTab:1
            }
        }
    }
</script>