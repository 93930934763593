import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/dashboard/home.vue'

//trading
import ecn from '../views/trading/ecn.vue'
import account_zero from '../views/trading/account_zero.vue'
import deposit_withdrawal from '../views/trading/deposit_withdrawal.vue'
import fees from '../views/trading/fees.vue'
import client_protection from '../views/trading/client_protection.vue'

//market
import forex from '../views/market/forex.vue'
import indices from '../views/market/indices.vue'
import commodities from '../views/market/commodities.vue'
import crypto from '../views/market/crypto.vue'

//platforms
import magno_terminal from '../views/platforms/magno_terminal.vue'
import magno_app from '../views/platforms/magno_app.vue'

//tools
import calculator from '../views/tools/calculator.vue'

//company
import why_us from '../views/company/why_us.vue'
import contact_us from '../views/company/contact_us.vue'

//partnership
import partnership from '../views/partners/partnership.vue'
import bonus from '../views/partners/bonus.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/ecn',
    name: 'ecn',
    component: ecn
  },
  {
    path: '/account-zero',
    name: 'Account Zero',
    component: account_zero
  },
  {
    path: '/deposit-withdrawal',
    name: 'Deposit Withdrawal',
    component: deposit_withdrawal
  },
  {
    path: '/fees',
    name: 'Fees',
    component: fees
  },
  {
    path: '/client-protection',
    name: 'Client Protection',
    component: client_protection
  },
  {
    path: '/forex',
    name: 'Forex',
    component: forex
  },
  {
    path: '/indices',
    name: 'Indices',
    component: indices
  },
  {
    path: '/commodities',
    name: 'Commodities',
    component: commodities
  },
  {
    path: '/crypto',
    name: 'Crypto',
    component: crypto
  },
  {
    path: '/magno-terminal',
    name: 'Magno Terminal',
    component: magno_terminal
  },
  {
    path: '/magno-app',
    name: 'Magno App',
    component: magno_app
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: calculator
  },
  {
    path: '/why_us',
    name: 'Why Us',
    component: why_us
  },
  {
    path: '/contact_us',
    name: 'Contact Us',
    component: contact_us
  },
  {
    path: '/partnership',
    name: 'Partners',
    component: partnership
  },
  {
    path: '/bonus',
    name: 'Bonus',
    component: bonus
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
