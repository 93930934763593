<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Crypto CFD</h3>
                    <h1>Trade Bitcoin and other Cryptocurrencies</h1>
                    <p>Trade in demand cryptocurrencies such as BTCUSD, ETHUSD, and LTCUSD with a max. leverage of up to 5x.</p>
                    <div class="cta">
                        <a href="" class="blue-btn mr-3">Register</a>
                        <a href="" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/crypto/crypto-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->
    
    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3> -->
                <h2>Why trade crypto with MagnoFX</h2>
                <p>From the US Tech 100 to the S&P, gain exposure to highly traded global indices with a broker that knows what matters to you.</p>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-umbrella.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Stop Out Protection</h3>
                            <p>Trade CFD Cryptos with an innovative market protection feature designed to safeguard your positions from short-term market fluctuations, preventing or minimizing stop outs and delays.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-withdraw.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast Withdrawals</h3>
                            <p>Simplify withdrawals for quick access to your funds. Opt for your preferred cryptocurrency when initiating a withdrawal request.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Never miss a pip. Execute your orders within milliseconds across all of our web and mobile trading platforms.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Three Columns End -->  
    
    <!-- Trading Instruments Start -->
    <section class="trading-instrument">
        <div class="container">
            <div class="text-center">
                <h2>Crypto trading conditions</h2>
                <h3 class="gradient-title">Market Execution</h3>
            </div>
            <div>
                <div class="trading-options">
                    <button onclick="dropdownMethod()" class="dropbtn">Select Mehod</button>
                    <div class="trading-options-list" id="trading-options-list">
                        <a href="" class="active">ECN</a>
                        <a href="">Zero</a>
                    </div>
                </div>
                <div class="trading-instrument__content" >
                    <div class="trading-instruments-table">
                        <table class="inner">
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th class="text-end">Avg. spread <br>pips</th>
                                    <th class="text-end">Commission <br>per lot/side</th>
                                    <th class="text-end">Margin <br>1:500 </th>
                                    <th class="text-end">Long swap <br>pips</th>
                                    <th class="text-end">Short swap <br>pips</th>
                                    <th class="text-end">Stop level* <br>pips</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        AUDUSDm
                                        <span>Australian Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            • Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                                <tr>
                                    <td>
                                        DXYm
                                        <span>US Dollar Index</span>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        EURUSDm
                                        <span>Euro vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        GBPUSDm
                                        <span>Great Britain Pound vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        NZDUSDm
                                        <span>New Zealand Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCADm
                                        <span>US Dollar vs Canadian Dollar </span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCHFm
                                        <span>US Dollar vs Swiss Franc</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDJPYm
                                        <span>US Dollar vs Japanese Yen</span>
                                        <div class="green-text">
                                            Extended Swap-free available 
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <!-- Trading Instruments End -->

    <!--- Two Columns Accordion Start --->
    <section class="two-columns-accordion">
        <div class="container">
            <!-- <h2>There are many advantages in trading Forex. </h2>
            <p>Here are a few reason for why people trade Forex:</p> -->
            <div class="row">
                <div class="col-md-6">
                    <div class="text">
                        <h2>Crypto market conditions</h2>
                        <p>The cryptocurrency market is a digital currency exchange powered by blockchain technology, facilitating secure transactions and the creation of new coins. Trading cryptocurrency derivatives enables you to expand your online portfolio and leverage price movements in cryptocurrencies, whether they are ascending or descending.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list">
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 1" :class="marketConditionTab==1?'active':''">
                                Crypto trading hours
                            </div>
                            <div v-show="marketConditionTab == 1">
                                <div class="answer">
                                    <p>You can trade cryptocurrencies 24/5.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 2" :class="marketConditionTab==2?'active':''">
                                Spreads
                            </div>
                            <div v-show="marketConditionTab == 2">
                                <div class="answer">
                                    <p>Spreads are subject to fluctuation. The spreads listed in the table above represent averages calculated. For real-time spreads, kindly consult the trading platform.</p>

                                    <p>It's important to note that spreads may widen during periods of reduced market liquidity, such as rollover time. This widening may persist until liquidity levels return to normal.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 3" :class="marketConditionTab==3?'active':''">
                                Swaps
                            </div>
                            <div v-show="marketConditionTab == 3">
                                <div class="answer">
                                    <p>Swap refers to the interest applied to all forex trading positions held overnight. Swaps are calculated at 22:00 GMT+0 daily, excluding weekends, until the position is closed. </p>

                                    <p>To assist you in estimating your swap costs, utilize our convenient MagnoFx calculator or the trading platform. It's important to note that when trading forex pairs, triple swaps are applied on Wednesdays to account for financing costs accrued over the weekend.</p>

                                    <p>For instruments indicated in the table above, swap charges are waived if you have Extended swap-free status. </p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 4" :class="marketConditionTab==4?'active':''">
                                Fixed margin requirements
                            </div>
                            <div v-show="marketConditionTab == 4">
                                <div class="answer">
                                    <p>Maximum available leverage for selection remains at 1:5 on cryptocurrencies.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 5" :class="marketConditionTab==5?'active':''">
                                Stop level
                            </div>
                            <div v-show="marketConditionTab == 5">
                                <div class="answer">
                                    <p>Stop level values listed in the table above are subject to change and may not be accessible for traders employing specific high-frequency trading strategies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Two Columns Accordion Accordion End --->

    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Frequently asked questions </h2>
            <div class="list">
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 1" :class="activeFaqs==1?'active':''">
                        What are cryptocurrencies?
                    </div>
                    <div v-show="activeFaqs == 1">
                        <div class="answer">
                            <p>Cryptocurrencies are digital or virtual currencies that use cryptography for security. Unlike traditional currencies issued by governments (like the dollar or euro), cryptocurrencies operate on decentralized networks based on blockchain technology—a distributed ledger that records all transactions across a network of computers.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 2" :class="activeFaqs==2?'active':''">
                        What are CFD cryptocurrencies?
                    </div>
                    <div v-show="activeFaqs == 2">
                        <div class="answer">
                            <p>CFD cryptocurrencies, or cryptocurrency CFDs (Contracts for Difference), are financial derivatives that allow traders to speculate on the price movements of cryptocurrencies without owning the underlying assets. When trading cryptocurrency CFDs, you are entering into an agreement with a broker to exchange the difference in the price of a cryptocurrency from the time you open the position to the time you close it.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 3" :class="activeFaqs==3?'active':''">
                        What are the benefits of trading cryptocurrency CFDs? 
                    </div>
                    <div v-show="activeFaqs == 3">
                        <div class="answer">
                            <ul class="circle">
                                <li>Access to Markets: CFD trading platforms provide access to a wide range of cryptocurrencies without the need for a cryptocurrency wallet.</li>
                                <li>Flexibility: Ability to trade both rising and falling markets.</li>
                                <li>Leverage: Potential to enhance returns with the use of leverage.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Full Accordion Accordion End --->
</template>
<script>
    import { useMainStore } from "@/store/index.ts";
    export default {
        setup(){
            return { store: useMainStore() };
        },
        data(){
            return {
                activeFaqs:'1',
                marketConditionTab:1
            }
        }
    }
</script>