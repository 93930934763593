<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Accoun Types</h3>
                    <h1>Accounts for trader</h1>
                    <p>Accounts tailored to the requirements of the most proficient traders. Advantages include spread-free or low-spread accounts optimised for day traders and scalpers.</p>
                    <div class="cta">
                        <a href="" class="blue-btn mr-3">Register</a>
                        <a href="" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/account-ecn/account-ecn-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->
    
    
    <!-- Compare Start -->
    <section class="compare">
        <div class="container">
            <div class="title">
                <h2>Accounts for matching to your trading requirements</h2>
                <p>Trading accounts with lots of benefits that meet the needs of modern traders. Join now to enjoy the benefits of our most well-liked account.</p>
            </div>
        </div>
        <div class="compare__content">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="compare__content__table left">
                            <div class="compare__content__table__box">
                                <h3>RAW</h3>
                                <div>
                                    <div>Initial deposit: </div>
                                    <div>$200</div>
                                </div>
                                <div>
                                    <div>min. Spread:</div>
                                    <div>0.0 pips</div>
                                </div>
                                <div>
                                    <div>max. Leverage: </div>
                                    <div>1:500</div>
                                </div>
                                <div>
                                    <div>min. Trade size: </div>
                                    <div>0.01</div>
                                </div>
                                <div>
                                    <div>Commission on Currencies:</div>
                                    <div>$2 per Lot</div>
                                </div>
                                <div>
                                    <div>Commission on Indices: </div>
                                    <div>$2 per Lot</div>
                                </div>
                                <div>
                                    <div>Commission on Commodities:</div>
                                    <div>$2 per Lot</div>
                                </div>
                                <div>
                                    <div>Commission on Crypto:</div>
                                    <div>0.1%</div>
                                </div>
                                <div>
                                    <div>Base currencies: </div>
                                    <div>USD, EUR, CAD, AUD, JPY, BTC, ETH</div>
                                </div>
                                <div>
                                    <div>Stop Out:</div>
                                    <div>0%</div>
                                </div>
                                <div>
                                    <div>SWAP Free:</div>
                                    <div>Available</div>
                                </div>
                                <div>
                                    <div>Scalping allowed:</div>
                                    <div>
                                        <img src="assets/images/icon-check-white.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <a href="" class="blue-btn">Register</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="compare__content__table right">
                            <div class="compare__content__table__box">
                                <h3>Standard</h3>
                                <div>
                                    <div>Initial deposit: </div>
                                    <div>$15</div>
                                </div>
                                <div>
                                    <div>min. Spread:</div>
                                    <div>0.6 pips</div>
                                </div>
                                <div>
                                    <div>max. Leverage: </div>
                                    <div>1:500</div>
                                </div>
                                <div>
                                    <div>min. Trade size: </div>
                                    <div>0.01</div>
                                </div>
                                <div>
                                    <div>Commission on Currencies:</div>
                                    <div>None</div>
                                </div>
                                <div>
                                    <div>Commission on Indices: </div>
                                    <div>None</div>
                                </div>
                                <div>
                                    <div>Commission on Commodities:</div>
                                    <div>None</div>
                                </div>
                                <div>
                                    <div>Commission on Crypto:</div>
                                    <div>None</div>
                                </div>
                                <div>
                                    <div>Base currencies: </div>
                                    <div>USD, EUR, CAD, AUD, JPY, BTC, ETH</div>
                                </div>
                                <div>
                                    <div>Stop Out:</div>
                                    <div>0%</div>
                                </div>
                                <div>
                                    <div>SWAP Free:</div>
                                    <div>Available</div>
                                </div>
                                <div>
                                    <div>Scalping allowed:</div>
                                    <div>
                                        <img src="assets/images/icon-check-white.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <a href="" class="blue-btn">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Compare End -->
    
    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3> -->
                <h2>Why trade crypto with MagnoFX</h2>
                <p>From the US Tech 100 to the S&P, gain exposure to highly traded global indices with a broker that knows what matters to you.</p>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-umbrella.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Stop Out Protection</h3>
                            <p>Trade CFD Cryptos with an innovative market protection feature designed to safeguard your positions from short-term market fluctuations, preventing or minimizing stop outs and delays.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-withdraw.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast Withdrawals</h3>
                            <p>Simplify withdrawals for quick access to your funds. Opt for your preferred cryptocurrency when initiating a withdrawal request.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Never miss a pip. Execute your orders within milliseconds across all of our web and mobile trading platforms.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Three Columns End -->  
    

    <!-- Trading Instruments Start -->
    <section class="trading-instrument">
        <div class="container">
            <div class="text-center">
                <h2>Crypto trading conditions</h2>
                <h3 class="gradient-title">Market Execution</h3>
            </div>
            <div>
                <div class="trading-options">
                    <button onclick="dropdownMethod()" class="dropbtn">Select Mehod</button>
                    <div class="trading-options-list" id="trading-options-list">
                        <a href="" class="active">ECN</a>
                        <a href="">Zero</a>
                    </div>
                </div>
                <div class="trading-instrument__content" >
                    <div class="trading-instruments-table">
                        <table class="inner">
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th class="text-end">Avg. spread <br>pips</th>
                                    <th class="text-end">Commission <br>per lot/side</th>
                                    <th class="text-end">Margin <br>1:500 </th>
                                    <th class="text-end">Long swap <br>pips</th>
                                    <th class="text-end">Short swap <br>pips</th>
                                    <th class="text-end">Stop level* <br>pips</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        AUDUSDm
                                        <span>Australian Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            • Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                                <tr>
                                    <td>
                                        DXYm
                                        <span>US Dollar Index</span>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        EURUSDm
                                        <span>Euro vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        GBPUSDm
                                        <span>Great Britain Pound vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        NZDUSDm
                                        <span>New Zealand Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCADm
                                        <span>US Dollar vs Canadian Dollar </span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCHFm
                                        <span>US Dollar vs Swiss Franc</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDJPYm
                                        <span>US Dollar vs Japanese Yen</span>
                                        <div class="green-text">
                                            Extended Swap-free available 
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <!-- Trading Instruments End -->

    <!--- Two Columns Accordion Start --->
    <section class="two-columns-accordion">
        <div class="container">
            <!-- <h2>There are many advantages in trading Forex. </h2>
            <p>Here are a few reason for why people trade Forex:</p> -->
            <div class="row">
                <div class="col-md-6">
                    <div class="text">
                        <h2>Crypto market conditions</h2>
                        <p>The cryptocurrency market is a digital currency exchange powered by blockchain technology, facilitating secure transactions and the creation of new coins. Trading cryptocurrency derivatives enables you to expand your online portfolio and leverage price movements in cryptocurrencies, whether they are ascending or descending.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list">
                        <div class="box">
                            <div class="question active">
                                Crypto trading hours
                            </div>
                            <div class="answercont">
                                <div class="answer">
                                    <p>You can trade cryptocurrencies 24/5.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question">
                                Spreads
                            </div>
                            <div class="answercont">
                                <div class="answer">
                                    <p>Spreads are subject to fluctuation. The spreads listed in the table above represent averages calculated. For real-time spreads, kindly consult the trading platform.</p>

                                    <p>It's important to note that spreads may widen during periods of reduced market liquidity, such as rollover time. This widening may persist until liquidity levels return to normal.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question">
                                Swaps
                            </div>
                            <div class="answercont">
                                <div class="answer">
                                    <p>Swap refers to the interest applied to all forex trading positions held overnight. Swaps are calculated at 22:00 GMT+0 daily, excluding weekends, until the position is closed. </p>

                                    <p>To assist you in estimating your swap costs, utilize our convenient MagnoFx calculator or the trading platform. It's important to note that when trading forex pairs, triple swaps are applied on Wednesdays to account for financing costs accrued over the weekend.</p>

                                    <p>For instruments indicated in the table above, swap charges are waived if you have Extended swap-free status. </p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question">
                                Fixed margin requirements
                            </div>
                            <div class="answercont">
                                <div class="answer">
                                    <p>Maximum available leverage for selection remains at 1:5 on cryptocurrencies.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question">
                                Stop level
                            </div>
                            <div class="answercont">
                                <div class="answer">
                                    <p>Stop level values listed in the table above are subject to change and may not be accessible for traders employing specific high-frequency trading strategies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Two Columns Accordion Accordion End --->

    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Frequently asked questions </h2>
            <div class="list">
                <div class="box">
                    <div class="question" :class="faqTab == 1?'active':''" v-on:click="faqTab = 1">
                        What are the advantage of trading stock index derivatives vs. investing in indices
                    </div>
                    <div v-show="faqTab == 1">
                        <div class="answer">
                            <p>Trading indices derivatives is a great way to gain exposure to the stock indices market without needing to own the underlying asset.</p>

                            <p> Because you're speculating on the performance of an index rather than investing in it, you can capitalize on the movements of prices, whether they're going up or down.</p>

                            <p>You can also use leverage to access the global indices market with a fraction of the capital you would need if you were to invest in indices directly.</p>

                            <p>Not only does this open up the world of major indices to so many more traders, but it also provides unique trading opportunities over multiple time frames, especially when combined with solid index chart technical analysis.</p>

                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 2?'active':''" v-on:click="faqTab = 2">
                        What is leverage in forex trading? 
                    </div>
                    <div v-show="faqTab == 2">
                        <div class="answer">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam asperiores sequi ab itaque harum officia eligendi totam vel incidunt non aliquam ipsam quidem vero, nulla, dolor exercitationem provident repellendus nisi.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 3?'active':''" v-on:click="faqTab = 3">
                        What is margin in online forex trading? 
                    </div>
                    <div v-show="faqTab == 3">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias. 
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 4?'active':''" v-on:click="faqTab = 4">
                        Does my account equity affect the maximum leverage I can use? 
                    </div>
                    <div v-show="faqTab == 4">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 5?'active':''" v-on:click="faqTab = 5">
                        Why are there higher margin requirements around news?
                    </div>
                    <div v-show="faqTab == 5">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 6?'active':''" v-on:click="faqTab = 6">
                        Do margin requirements change around weekends and holidays? 
                    </div>
                    <div v-show="faqTab == 6">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 7?'active':''" v-on:click="faqTab = 7">
                        When does the weekend period of increased margin requirements start and finish?  
                    </div>
                    <div v-show="faqTab == 7">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Full Accordion Accordion End --->
</template>
<script>
    export default {
        data(){
            return{
                faqTab:1
            }
        },
        methods:{},
        created(){}
    }
</script>