<template>
  <navbar ></navbar>
  <router-view/>
  <footerNavbar></footerNavbar>
</template>
<script>
import header from "./components/shared/header.vue"
import footer from "./components/shared/footer.vue"
  export default {
    data(){
      return {
        
      }
    },
    components:{
      navbar: header,
      footerNavbar:footer
    }
  }
</script>
<style lang="scss">
  @import '../public/assets/scss/style.css';
</style>

