import { defineStore } from 'pinia'

import * as API from './api/rx-factory'
import * as ENDPOINTS from './api/endpoints'

import axios from 'axios';

export const useMainStore = defineStore('store', {
  state: () => {
    return {
      clientRegistrationLink: 'www.google.com',
      loading:false
    }
  },
  persist: {
    storage: sessionStorage,
    paths: ['someState'],
  },
  actions:{
    contactUs(perams:any, loader:any = false){
      if (loader) { this.loading = true; }
      return new Promise((resolve, reject) => {
        axios.post(ENDPOINTS.EP_LOGIN, perams)
        .then(function (response) {
          // console.log("response----",response);
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
          // console.log("error----",error);
        });
      })
    }
  }
})