<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Indices CFD</h3>
                    <h1>Trade popular Indices on RAW spread</h1>
                    <p>Conquer the global index market by capitalising on trading conditions that are tailored to support your strategy.</p>
                    <div class="cta">
                        <a href="" class="blue-btn mr-3">Register</a>
                        <a href="" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/indices/indices-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->
    
    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3> -->
                <h2>Why trade indices with MagnoFX</h2>
                <p>Access extensively traded global indices, including the S&P and the US Tech 100, through a broker who understands your requirements.</p>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-umbrella.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Stop Out Protection</h3>
                            <p>Trade FX with an innovative market protection feature designed to safeguard your positions from short-term market fluctuations, preventing or minimizing stop outs and delays.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-candles.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Low and stable spreads</h3>
                            <p>Engage in FX trading with consistently low and and transparent fee. Benefit from narrow spreads that remain steady, even amidst economic news releases and market events.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Leverage the frequent price movements of popular currency pairs with lightning-fast execution. Have your FX trading orders executed within milliseconds.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Three Columns End -->  
    
    <!-- Trading Instruments Start -->
    <section class="trading-instrument">
        <div class="container">
            <div class="text-center">
                <h2>Indices trading conditions</h2>
                <h3 class="gradient-title">Market Execution</h3>
            </div>
            <div>
                <div class="trading-options">
                    <button onclick="dropdownMethod()" class="dropbtn">Select Mehod</button>
                    <div class="trading-options-list" id="trading-options-list">
                        <a href="" class="active">ECN</a>
                        <a href="">Zero</a>
                    </div>
                </div>
                <div class="trading-instrument__content" >
                    <div class="trading-instruments-table">
                        <table class="inner">
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th class="text-end">Avg. spread <br>pips</th>
                                    <th class="text-end">Commission <br>per lot/side</th>
                                    <th class="text-end">Margin <br>1:500 </th>
                                    <th class="text-end">Long swap <br>pips</th>
                                    <th class="text-end">Short swap <br>pips</th>
                                    <th class="text-end">Stop level* <br>pips</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        AUDUSDm
                                        <span>Australian Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            • Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                                <tr>
                                    <td>
                                        DXYm
                                        <span>US Dollar Index</span>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        EURUSDm
                                        <span>Euro vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        GBPUSDm
                                        <span>Great Britain Pound vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        NZDUSDm
                                        <span>New Zealand Dollar vs US Dollar</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCADm
                                        <span>US Dollar vs Canadian Dollar </span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDCHFm
                                        <span>US Dollar vs Swiss Franc</span>
                                        <div class="green-text">
                                            Extended Swap-free available
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                <tr>
                                    <td>
                                        USDJPYm
                                        <span>US Dollar vs Japanese Yen</span>
                                        <div class="green-text">
                                            Extended Swap-free available 
                                        </div>
                                    </td>
                                    <td class="text-end">1.4</td>
                                    <td class="text-end">$1</td>
                                    <td class="text-end">0.05%</td>
                                    <td class="text-end">−0.31</td>
                                    <td class="text-end">0</td>
                                    <td class="text-end">0</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <!-- Trading Instruments End -->

    <!--- Two Columns Accordion Start --->
    <section class="two-columns-accordion">
        <div class="container">
            <!-- <h2>There are many advantages in trading Forex. </h2>
            <p>Here are a few reason for why people trade Forex:</p> -->
            <div class="row">
                <div class="col-md-6">
                    <div class="text">
                        <h2>Indices market conditions</h2>
                        <p>The global index market comprises an extensive network of stock indices, often comprising thousands or hundreds of stocks, ranging in size from large-cap to small-cap companies. Speculation on the price movements of diverse stock indices is possible via the award-winning trading platform of Magno Fx, without the need to purchase the underlying asset.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list">
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 1" :class="marketConditionTab==1?'active':''">
                                Spreads
                            </div>
                            <div v-show="marketConditionTab == 1">
                                <div class="answer">
                                    <p>Spreads are always floating, so the spreads in the table above are yesterday’s averages. For live spreads, please refer to the trading platform.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 2" :class="marketConditionTab==2?'active':''">
                                Spreads
                            </div>
                            <div v-show="marketConditionTab == 2">
                                <div class="answer">
                                    <p>Spreads are subject to fluctuation. The spreads listed in the table above represent averages calculated. For real-time spreads, kindly consult the trading platform.</p>

                                    <p>It's important to note that spreads may widen during periods of reduced market liquidity, such as rollover time. This widening may persist until liquidity levels return to normal.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 3" :class="marketConditionTab==3?'active':''">
                                Swaps
                            </div>
                            <div v-show="marketConditionTab == 3">
                                <div class="answer">
                                    <p>Swap refers to the interest applied to all forex trading positions held overnight. Swaps are calculated at 22:00 GMT+0 daily, excluding weekends, until the position is closed. </p>

                                    <p>To assist you in estimating your swap costs, utilize our convenient MagnoFx calculator or the trading platform. It's important to note that when trading forex pairs, triple swaps are applied on Wednesdays to account for financing costs accrued over the weekend.</p>

                                    <p>For instruments indicated in the table above, swap charges are waived if you have Extended swap-free status. </p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 4" :class="marketConditionTab==4?'active':''">
                                Fixed margin requirements
                            </div>
                            <div v-show="marketConditionTab == 4">
                                <div class="answer">
                                    <p>Maximum available leverage for selection remains at 1:500 on CFD indices.</p>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="question" v-on:click="marketConditionTab = 5" :class="marketConditionTab==5?'active':''">
                                Stop level
                            </div>
                            <div v-show="marketConditionTab == 5">
                                <div class="answer">
                                    <p>Stop level values listed in the table above are subject to change and may not be accessible for traders employing specific high-frequency trading strategies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Two Columns Accordion Accordion End --->

    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Frequently asked questions </h2>
            <div class="list">
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 1" :class="activeFaqs==1?'active':''">
                        What are the advantages of trading CFD Indices?
                    </div>
                    <div v-show="activeFaqs == 1">
                        <div class="answer">
                            <p>Trading CFD Indices presents an excellent avenue for accessing the stock indices market without possessing the actual assets.</p>

                            <p>By speculating on index performance rather than investing in it outright, you can profit from price fluctuations, regardless of their direction.</p>

                            <p>Leveraging allows entry into the global indices market with a fraction of the capital needed for direct investment. </p>

                            <p>This not only broadens access to major indices for many traders but also offers diverse trading prospects across various time frames, particularly when complemented with robust technical analysis of index charts.</p>

                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 2" :class="activeFaqs==2?'active':''">
                        What is the difference between CFD and indices? 
                    </div>
                    <div v-show="activeFaqs == 2">
                        <div class="answer">
                            <p>The key contrast lies in leverage trading, which is available with Index CFDs, offering increased market exposure with less capital. With Index CFDs, you can trade a range of indexes, including but not limited to S&P 500, NASDAQ 100, Dow Jones Industrial Average, DAX30, FTSE 100, and others.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 3" :class="activeFaqs==3?'active':''">
                        Differences between trading index CFDs and stock CFDs
                    </div>
                    <div v-show="activeFaqs == 3">
                        <div class="answer">
                            <p>Although both CFD Indices and Stock CFDs enable speculation on market direction without asset ownership, they exhibit significant disparities:</p>
                            <p>Market scope: CFD Indices permit speculation on entire sectors or national economies, whereas Stock CFDs are confined to individual companies.</p>
                            <p>Trading timeframe: While many CFD Indices offer 24-hour trading, Stock CFDs typically adhere to exchange trading hours.</p>
                            <p>Volatility: CFD Indices frequently demonstrate lower price volatility compared to individual stocks, reflecting the broader market they represent.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 4" :class="activeFaqs==4?'active':''">
                        What is the leverage available on CFD Indices? 
                    </div>
                    <div v-show="activeFaqs == 4">
                        <div class="answer">
                            <p>Magno Fx offers a maximum leverage of up to 200x on all CFD Indices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Full Accordion Accordion End --->
</template>
<script>
    import { useMainStore } from "@/store/index.ts";
    export default {
        setup(){
            return { store: useMainStore() };
        },
        data(){
            return {
                activeFaqs:'1',
                marketConditionTab:1
            }
        }
    }
</script>