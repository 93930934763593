import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

// Functions first
// import "../node_modules/bootstrap/scss/functions";
import "../node_modules/bootstrap/scss/_functions.scss";


import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

//tel input field
// import VueTelInput from 'vue3-tel-input'
// import 'vue3-tel-input/dist/vue3-tel-input.css'

//vee validation rules
import { defineRule, configure } from 'vee-validate'
configure({ validateOnBlur: false, validateOnChange: true, validateOnInput: true, validateOnModelUpdate: false });

defineRule('required', (value, t, ctx) => {
    if (!value || (value + '').trim() == '') {
        return ctx.field + ' field is required.';
    }
    return true;
})



import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)


const app = createApp(App)


// app.use(store)
app.use(router)
app.use(Toast);
// app.use(VueTelInput);
app.use(pinia)
app.mount('#app')
