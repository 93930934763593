<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content pb-0">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Contact Us</h3>
                    <h1>Keep in touch <br> with us</h1>
                    <p>We offer support to our clients in various different languages, five days a week, twenty-four hours a day.</p>
                    <!-- <div class="cta">
                        <a href="" class="blue-btn mr-3">Register</a>
                        <a href="" class="grey-btn">Try free demo</a>
                    </div> -->
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/contact/contact-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->
    
    <!-- Contact Us Start -->
    <section class="contact-us">
        <div class="general-inquries">
            <div class="container">
                <div class="title">
                    <h2>General inquiries</h2>
                    <p>Our leading market conditions, trading features and proprietary protections give your strategy the advantage it deserves.</p>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="general-inquries-box">
                            <div class="icon">
                                <img src="assets/images/icon-envelope.png" alt="">
                            </div>
                            <div class="text">
                                <h3>Send us an email</h3>
                                <p>Reach out via support@magnofx .com and we'll get back to you in 24 hours.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="general-inquries-box">
                            <div class="icon">
                                <img src="assets/images/icon-envelope.png" alt="">
                            </div>
                            <div class="text">
                                <h3>Complaint</h3>
                                <p>If you would like to submit a complaint please email us on <a href="mailto:complaints@magnofx.com">complaints@magnofx.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-form">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="text">
                            <h2>Message Us</h2>
                            <p>Learn more about our safe and secure trading conditions and protection </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <Form @submit="contactUsSubmit()" ref="contactForm">
                            <div class="form-fields">
                                <label for="">Full Name</label>
                                <Field type="text" name="Name" v-model="form.name" rules="required" />
                                <ErrorMessage name="Name" class="errorMessage" />
                            </div>
                            <div class="form-fields">
                                <label for="">Email</label>
                                <Field type="email" name="Email" v-model="form.email" :rules="contactEmail" />
                                <ErrorMessage name="Email" class="errorMessage" />
                            </div>
                            <div class="form-fields">
                                <label for="">Mobile Number</label>
                                <Field type="text" name="Contact Number" v-model="form.contactNumber" :rules="contactNumber" />
                                <ErrorMessage name="Contact Number" class="errorMessage" />
                            </div>
                            <div class="form-fields">
                                <label for="">Message</label>
                                <Field as="textarea" name="Message" v-model="form.message" rows="10" rules="required" />
                                <ErrorMessage name="Message" class="errorMessage" />
                            </div>
                            <button class="blue-btn mt-3">Send</button>
                        </Form>
                        <div class="d-flex justify-content-center" v-if="(responseError || successMessage) && (responseError !='' || successMessage !='')">
                            <p class="successMessage" v-if="successMessage && successMessage !=''">{{successMessage}}</p>
                            <p class="errorMessage" v-if="responseError && responseError !=''">{{responseError}}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Contact Us End -->  
    
    <!-- Image Start -->
    <section class="image-section">
        <div class="container">
            <img src="assets/images/contact/woman.jpg" alt="">
        </div>
    </section>
    <!-- Contact Us End -->  
</template>
<script>
    import { useMainStore } from '@/store/index.ts'
    import { Form, Field, ErrorMessage } from "vee-validate";
    
    export default {
        setup() {
            return { store: useMainStore() };
        },
        data(){            
            return{
                form:{
                    name:'',
                    email:'',
                    contactNumber:'',
                    message:''
                },
                successMessage:"",
                responseError:""
            }
        },
        components:{
            Form, 
            Field, 
            ErrorMessage
        },
        methods:{
            contactUsSubmit(){
                this.store.contactUs(this.form).then(res=>{
                    this.successMessage = "Thankyou for contacting us. We will get back to you shortly."
                    this.$refs.contactForm.resetForm();
                    setTimeout(() => {
                        this.successMessage=""
                        return this.successMessage;
                    }, 5000);

                }).catch(error=> {
                    this.$refs.contactForm.resetForm();
                    this.responseError=error
                })
            },
            contactNumber(value){
                const hasCharacters = /[a-zA-Z]/
                if(value == '' || value == undefined){
                    return "Please provide the contact number.";
                }
                else if(value && (value.length < 10 || value.length > 15 )){
                    return "Contact number should be numeric and should be greater than 10 and less than equal to 15.";
                }
                else if(value && (hasCharacters.test(value))){
                    return "Contact number should be numeric and should be greater than 10 and less than equal to 15.";
                }
                return true;
            },
            contactEmail(value){
                const character = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                if(value == '' || value == undefined){
                    return "Please provide the Email Address.";
                }
                else if(value.length < 3 || character.test(value)==false ){
                    return "Please provide valid Email Address.";
                }
                return true;
            }
        },
        created(){

        }
    }
</script>