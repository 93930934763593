<template>
    <footer>
        <div class="footer-top">
            <div class="footer-content">
                <div>
                    <h2>Begin trading with a reliable broker</h2>
                    <p>Find out why Magno Fx is the preferred platform for thousands of traders and partners.</p>
                    <div class="cta">
                        <a href="" class="blue-btn">Register</a>
                        <a href="" class="grey-btn">Try  free demo</a>
                    </div>
                </div>
                <div>
                    <img src="/assets/images/footer-laptop.png" alt="">
                </div>         
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 footer-bottom-left">
                        <div class="footer-bottom__content">
                            <div class="footer-logo">
                                <a href="">
                                    <img src="/assets/images/logo.png" alt="">
                                </a>
                            </div>
                            <div class="row mb-md-5">
                                <div class="col-md-4">
                                    <h3>Trading Products</h3>
                                    <ul>
                                        <li>
                                            <router-link :to="{name:'Forex'}">Fx Markets</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name:'Commodities'}">Commodities Market</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name:'Indices'}">CFD Indices</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name:'Crypto'}">Crypto Currencies</router-link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <h3>Account types</h3>
                                    <ul>
                                        <li>
                                            <router-link :to="{name:'Account Zero'}">ZERO Account</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name:'ecn'}">ECN Account</router-link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <h3>Partnerships</h3>
                                    <ul>
                                        <li>
                                            <router-link :to="{name:'Partners'}">Become an affiliate</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <h3>Transfer Methods</h3>
                                    <ul>
                                        <li>
                                            <router-link :to="{name:'Deposit Withdrawal'}">Deposits and Withdrawals</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name:'Fees'}">Fees</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name:'Client Protection'}">Client security</router-link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <h3>Tools</h3>
                                    <ul>
                                        <li>
                                            <router-link :to="{name:'Calculator'}">Trading Calculator</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name:'Bonus'}">Bonus</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="footer-bottom__content-half mb-4">
                            <div class="row">
                                <div class="col">
                                    <h2>Let’s work together</h2>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h3>Why Regal Markets?</h3>
                                            <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ng elit... </p>
                                            <a href="">Read more</a>
                                        </div>
                                        <div class="col-md-6">
                                            <h3>Contact Us</h3>
                                            <p>In need of more assistance? Give us a call <span class="number">+35725008105</span>, or reach out via <a href="mailto:support@regalmarket.com">support@regalmarket.com</a> and we'll get back to you in 24 hours.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="footer-bottom__content-quarter left">
                                    <h2>Trading Platforms</h2>
                                    <p>Regal Trading Platform</p>
                                    <div>
                                        <img src="/assets/images/footer-laptop2.png" alt="">
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-6">
                                <div class="footer-bottom__content-quarter right">
                                    <div>
                                        <h2></h2>
                                        <p>Regal Trade App</p>
                                    </div>
                                    <div>
                                        <img src="/assets/images/footer-tablet.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-text">
            <div class="container">
                <h3>Risk Warning:</h3>
                <p>Trading leveraged products such as CFDs and Forex carries a substantial risk of financial loss. These intricate instruments cater to both professional and retail clients. It is essential to acknowledge that client accounts may sustain losses, potentially exceeding the deposited funds. Given the chance of losing more than the initial deposit, we strongly recommend trading responsibly. In case of trading losses, it is crucial to ensure that they do not significantly impact your personal and financial well-being.</p>
                <h3>Disclaimer:</h3>
                <p>Magno Fx is not intended for distribution to or use by any person in any jurisdiction where such distribution or use would be contrary to local law or regulation. You must be 18 years of or of the legal age determined by your local country. By registering an account with Magno Fx, you confirm that you are doing so voluntarily, without </p>
                <div>
                    <span>©2024 www.magnofx.com</span>
                    <span>All Rights Reserved</span>
                    <a href="">Terms of Use</a>
                    <a href="">Privacy Policy</a>
                    <a href="">Withdrawal Policy</a>
                    <a href="">Deposit Policy</a>
                </div>
            </div>
        </div>
    </footer>
</template>